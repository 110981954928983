import {
  Box,
  CircularProgress,
  Container,
  Grid,
  List,
  ListItem,
  Paper,
  Typography
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { TextField } from "mui-rff";
import React, { useEffect } from "react";
import { Form } from "react-final-form";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MakeAsyncFunction from "react-redux-promise-listener";
import { Redirect, useParams } from "react-router-dom";
import { accessoriesActions } from "../../../../actions/accessories.actions";
import { appActions } from "../../../../actions/app.actions";
import {
  EDIT_PROJECT_ERROR,
  EDIT_PROJECT_REQUEST,
  EDIT_PROJECT_RESPONSE,
  projectsActions,
} from "../../../../actions/projects.actions";
import StepperButtons from "../../../../components/projects/StepperButtons";
import { StepperHeader } from "../../../../components/projects/StepperHeader";
import { getProjectStep, useStyles } from "../../../../helpers/project";
import { promiseListener } from "../../../../helpers/store";

export default function Step7() {
  let { projectId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep] = React.useState(6);
  let submit;

  const isFetchingProject = useSelector((state) => {
    return state.projects.isFetching;
  });

  const project = useSelector((state) => {
    return state.projects.all[projectId] || {};
  }, shallowEqual);

  const allAccessories = useSelector((state) => {
    return Object.values(state.accessories.all) || [];
  }, shallowEqual);

  const initialValues = {};
  allAccessories.forEach(accessory => {
    const projectAccessory = project.accessories?.find(pAccessory => pAccessory.accessory.reference === accessory.reference);

    initialValues[`accessory-${accessory.id}`] = {
      price: projectAccessory ? projectAccessory.price : 0,
      quantity: projectAccessory ? projectAccessory.quantity : 0
    };
  });

  useEffect(() => {
    dispatch(projectsActions.fetchProjectIfNeeded(projectId));
    dispatch(accessoriesActions.fetchAccessoriesIfNeeded());
    dispatch(appActions.setNextPage("/thermo/projects/{currentProject}/step8"));
    dispatch(appActions.setCurrentProject(projectId));
  }, [dispatch, projectId, isFetchingProject]);

  if (!isFetchingProject && project.name && getProjectStep(project) < 5) {
    return (
      <Redirect to={`/thermo/projects/${project.id}/step${getProjectStep(project)}`} />
      );
    }

  return (
    <>
      {isFetchingProject && !project.name && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <CircularProgress size={100} />
        </Box>
      )}
      {!isFetchingProject && !project.name && (
        <Alert severity="error">Projet non trouvé !</Alert>
      )}

      {project.name && (
        <MakeAsyncFunction
          listener={promiseListener}
          start={EDIT_PROJECT_REQUEST}
          resolve={EDIT_PROJECT_RESPONSE}
          reject={EDIT_PROJECT_ERROR}
        >
          {(onSubmit) => (
            <Form
              initialValues={initialValues}
              onSubmit={(form) => {
                const accessories = allAccessories.map(accessory => {
                  return {
                    id: `${project.id}-${accessory.id}`,
                    accessory: {
                      id: accessory.id
                    },
                    quantity: parseInt(form[`accessory-${accessory.id}`]['quantity']),
                    price: parseInt(form[`accessory-${accessory.id}`]['price'])
                  }
                });

                return onSubmit({accessories});
              }}
              mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }}
              render={({
                form,
                handleSubmit,
                submitting,
                pristine,
                values,
              }) => {
                submit = handleSubmit;

                return (
                  <div className={classes.root}>
                    <StepperHeader
                      submit={submit}
                      activeStep={activeStep}
                      projectId={projectId}
                      submitting={submitting}
                      shouldLink={pristine}
                      disabled={false}
                    />
                    <Container maxWidth="lg">
                      <form onSubmit={handleSubmit}>
                        <Box
                          mt={2}
                          mb={3}
                          display="flex"
                          flexDirection="column"
                          flexGrow="1"
                        >
                          <Grid className={"mb-4"} container spacing={2}>
                            {allAccessories.map(accessory => {
                              const accessorySize = accessory.reference.split('-')[1];

                              return <Grid item xs={6}>
                                <Paper>
                                  <Box display="flex" flexDirection="column">
                                    <Box
                                      mt={1}
                                      display="flex"
                                      justifyContent="center"
                                    >
                                      <Typography variant="h5">
                                        {accessory.name}
                                      </Typography>
                                    </Box>

                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                    >
                                      <Box
                                        display="flex"
                                        flexGrow={1}
                                        justifyContent="center"
                                        alignItems="center"
                                        flexDirection="column"
                                        m={2}
                                      >
                                        <TextField
                                          label="Quantité"
                                          name={`[accessory-${accessory.id}][quantity]`}
                                          type="number"
                                          variant="outlined"
                                        />

                                        <TextField
                                          label="Prix unitaire"
                                          name={`[accessory-${accessory.id}][price]`}
                                          type="number"
                                          style={{marginTop: '2em'}}
                                          variant="outlined"
                                        />
                                      </Box>
                                    </Box>

                                    <Box
                                      display="flex"
                                      flexGrow={1}
                                      justifyContent="center"
                                      alignItems="center"
                                      flexDirection="column"
                                      m={2}
                                    >
                                      <Typography variant="body1">
                                        <List>
                                          <ListItem>Joint butyl ETANCOPAST ({accessorySize === '70' ? '286' : '26'} ml)</ListItem>
                                          <ListItem>Foret HSS 150 mm utile</ListItem>
                                          <ListItem>Fixation PLASTINOX A2 (x{accessorySize === '70' ? '100' : '10'})</ListItem>
                                        </List>
                                      </Typography>
                                    </Box>

                                    <Box
                                      display="flex"
                                      flexGrow={1}
                                      justifyContent="center"
                                      alignItems="center"
                                      flexDirection="column"
                                      m={2}
                                    >
                                      <img src={require(`../../../../assets/images/accessory-${accessorySize}.png`)} />
                                    </Box>
                                  </Box>
                                </Paper>
                              </Grid>
                            })}
                          </Grid>
                        </Box>
                        <Container maxWidth="lg">
                          <StepperButtons
                            submit={submit}
                            activeStep={activeStep}
                            projectId={projectId}
                            submitting={submitting}
                            shouldLink={pristine}
                            disabled={false}
                          />
                        </Container>
                      </form>
                    </Container>
                  </div>
                );
              }}
            />
          )}
        </MakeAsyncFunction>
      )}
    </>
  );
}
