import {
  Box,
  CircularProgress,
  Container,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  makeStyles
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import WarningIcon from '@material-ui/icons/Warning';
import Alert from "@material-ui/lab/Alert";
import arrayMutators from "final-form-arrays";
import { Switches, TextField } from "mui-rff";
import React, { useEffect } from "react";
import { Form, FormSpy } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MakeAsyncFunction from "react-redux-promise-listener";
import { Redirect, useParams } from "react-router-dom";
import { appActions } from "../../../../actions/app.actions";
import { productOptionsActions } from "../../../../actions/productOption.actions";
import {
  EDIT_PROJECT_ERROR,
  EDIT_PROJECT_REQUEST,
  EDIT_PROJECT_RESPONSE,
  projectsActions,
} from "../../../../actions/projects.actions";
import StepperButtons from "../../../../components/projects/StepperButtons";
import { StepperHeader } from "../../../../components/projects/StepperHeader";
import { getProjectStep } from "../../../../helpers/project";
import { promiseListener } from "../../../../helpers/store";

const useStyles = makeStyles((theme) => ({
  circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px',
    height: '20px',
    border: '1px solid',
    borderRadius: '50%',
    fontWeight: 700,
    color: 'white',
  },
  gray: {
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
    borderColor: 'rgba(0, 0, 0, 0.54)'
  },
  red: {
    backgroundColor: '#dc2c32',
    borderColor: '#dc2c32'
  },
  aside: {
    color: '#dc2c32',
    borderColor: '#dc2c32'
  },
  asideTypo: {
    fontWeight: 700,
    paddingRight: '1em'
  },
  headerPart: {
    marginRight: '1em'
  },
  error: {
    border: '1px solid red'
  },
  notUsed: {
    backgroundColor: 'lightgray'
  },
  switchLabel: {
    marginRight: ".5em"
  },
  pageSeparator: {
    marginTop: '1em',
    marginBottom: '1em'
  }
}));

export default function Step6() {
  let { projectId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep] = React.useState(5);
  let submit;
  const [error, setError] = React.useState(true);

  const isFetchingProject = useSelector((state) => {
    return state.projects.isFetching;
  });

  const project = useSelector((state) => {
    return state.projects.all[projectId] || {};
  }, shallowEqual);

  const productOptions = useSelector((state) => {
    return Object.values(state.productOptions.all) || [];
  }, shallowEqual);

  const currentOption = productOptions.find(option => project.productVariant?.product?.options?.id === option.id);
  const isReinforcedProduct = currentOption?.reference === 'R' || currentOption?.reference === 'TR';

  useEffect(() => {
    dispatch(projectsActions.fetchProjectIfNeeded(projectId));
    dispatch(productOptionsActions.fetchProductOptionsIfNeeded());
    dispatch(appActions.setNextPage("/thermo/projects/{currentProject}/step7"));
    dispatch(appActions.setCurrentProject(projectId));
  }, [dispatch, projectId, isFetchingProject]);

  if (!isFetchingProject && project.name && getProjectStep(project) < 6) {
    return (
      <Redirect to={`/thermo/projects/${project.id}/step${getProjectStep(project)}`} />
    );
  }

  if (project.name) {
    let indexes = [];
    const dimensions = project.dimensions;

    dimensions.forEach(dimension => {
      indexes.push(dimension.position);
    });

    for (let i = 0; i < 12; i++) {
      if (!indexes.includes(i)) {
        dimensions.push({
          position: i,
          nbPanels: 0,
          direction: '',
          distances: [],
          boxLength: 0,
          covering: 0,
          panelTotalLength: 0,
          errors: {
            inError: false,
            covering: '',
            boxLength: '',
            distances: '',
            panelTotalLength: ''
          }
        });
      }
    }
  }

  return (
    <>
      {isFetchingProject && !project.name && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <CircularProgress size={100} />
        </Box>
      )}
      {!isFetchingProject && !project.name && (
        <Alert severity="error">Projet non trouvé !</Alert>
      )}

      {project.name && (
        <MakeAsyncFunction
          listener={promiseListener}
          start={EDIT_PROJECT_REQUEST}
          resolve={EDIT_PROJECT_RESPONSE}
          reject={EDIT_PROJECT_ERROR}
        >
          {(onSubmit) => (
            <Form
              initialValues={project}
              initialValuesEqual={(old, newOne) => old.id === newOne.id}
              onSubmit={(form) => {

                form.dimensions = form.dimensions.map((e) => {
                  e.nbPanels = parseInt(e.nbPanels);
                  e.boxLength = parseInt(e.boxLength);
                  e.covering = parseInt(e.covering);
                  e.panelTotalLength = parseInt(e.panelTotalLength);
                  e.direction = e.direction ? 'D' : '';

                  return e;
                });

                return onSubmit({ dimensions: form.dimensions });
              }}
              mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
                ...arrayMutators,
              }}
              render={({
                form,
                handleSubmit,
                submitting,
                pristine,
                values,
              }) => {
                submit = handleSubmit;

                return (
                  <div className={classes.root}>
                    <StepperHeader
                      submit={submit}
                      activeStep={activeStep}
                      projectId={projectId}
                      submitting={submitting}
                      shouldLink={pristine}
                      disabled={error}
                    />
                    <Container maxWidth="lg">
                      <form onSubmit={handleSubmit}>
                        <Box
                          mt={2}
                          mb={3}
                          display="flex"
                          flexDirection="column"
                          flexGrow="1"
                        >
                          <Box
                            display="flex"
                            justifyContent="center"
                            flexDirection="column"
                            m={2}
                          >
                            <img src={require(`../../../../assets/images/dimensions-${isReinforcedProduct ? 'reinforced' : 'standard'}.png`)} />
                          </Box>
                          <FieldArray name="dimensions">
                            {({ fields }) => {
                              let dimensionHasError = false;

                              fields.value.forEach(dimension => {
                                if (typeof dimension.errors === 'undefined') {
                                  dimension.errors = {
                                    inError: false,
                                    covering: '',
                                    boxLength: '',
                                    distances: '',
                                    panelTotalLength: ''
                                  };
                                }

                                if (dimension.nbPanels > 0) {
                                  dimension.errors.inError = false;
                                  let distancesSum = 0;

                                  if (!isReinforcedProduct) {
                                    if (dimension.distances.length > 0) {
                                      let currentIndex = 0;
                                      dimension.errors.distances = '';

                                      dimension.distances.forEach(distance => {
                                        if (distance) {
                                          const numberDistance = parseInt(distance);
                                          distancesSum += numberDistance;

                                          if (dimension.errors.distances === '') {
                                            if (currentIndex > 0 && typeof dimension.distances[currentIndex - 1] === 'undefined') {
                                              dimension.errors.distances = `La distance D${currentIndex + 1} est renseignée alors qu'une distance précédente est vide`;
                                              dimensionHasError = true;
                                              dimension.errors.inError = true;
                                            } else if (numberDistance > 1500) {
                                              dimension.errors.distances = "Attention la portée maximale autorisée par le DTU 40.35 des couvertures en panneaux sandwich est de 1500mm, votre projet pourra donc être refusé par un bureau de contrôle.";
                                              dimensionHasError = true;
                                              dimension.errors.inError = true;
                                            }
                                          }
                                        }

                                        currentIndex++;
                                      })
                                    }

                                    dimension.boxLength = distancesSum;
                                  }

                                  if (dimension.boxLength > 0) {
                                    let boxLengthhasError = false;
                                    let boxLengthErrorMessage = '';

                                    // if (!isReinforcedProduct && distancesSum != dimension.boxLength) {
                                    //   boxLengthErrorMessage = "La somme des entraxes n'est pas égale à la longueur du caisson.";
                                    //   boxLengthhasError = true;
                                    // } else if (dimension.boxLength < 1000 || dimension.boxLength > 6500) {
                                    if (dimension.boxLength < 1000 || dimension.boxLength > 6500) {
                                      boxLengthErrorMessage = "Attention la longueur du caisson THERMO doit être comprise entre 1m00 et 6m50";
                                      boxLengthhasError = true;
                                    }

                                    if (boxLengthhasError) {
                                      dimension.errors.boxLength = boxLengthErrorMessage;
                                      dimensionHasError = true;
                                      dimension.errors.inError = true;
                                    } else {
                                      dimension.errors.boxLength = '';
                                    }
                                  }

                                  // if (dimension.covering < 200) {
                                  //   dimension.errors.covering = "La longueur de recouvrement rentrée est inférieure au minimum requis, la pose sera donc possible uniquement si le panneau termine à l'égout.";
                                  //   dimensionHasError = true;
                                  //   dimension.errors.inError = true;
                                  // } else {
                                  //   dimension.errors.covering = '';
                                  // }

                                  const length = parseInt(dimension.covering) + parseInt(dimension.boxLength);
                                  dimension.panelTotalLength = length;

                                  // if (dimension.panelTotalLength != totalLength) {
                                  //   dimension.errors.panelTotalLength = "La longueur totale n'est pas égale à la somme de la longueur du caisson et du recouvrement";
                                  //   dimensionHasError = true;
                                  //   dimension.errors.inError = true;
                                  // } else {
                                  //   dimension.errors.panelTotalLength = '';
                                  // }
                                } else {
                                  dimension.errors.inError = false;
                                }
                              });
                              
                              setError(dimensionHasError);
                              return (
                                <>
                                  {fields.map((dimension, index) => {
                                    return (
                                      <FormSpy subscription={{ values: true }}>
                                        {({ values }) => (
                                          <>
                                          {index + 1 === 7 && <Typography className={classes.pageSeparator}>Nouvelle page dans le devis</Typography>}

                                          <ExpansionPanel key={index} className={values.dimensions[index].errors.inError ? classes.error : values.dimensions[index].nbPanels === 0 ? classes.notUsed : ''}>

                                            <ExpansionPanelSummary
                                              expandIcon={<ExpandMoreIcon />}
                                              aria-controls="panel1a-content"
                                              id="panel1a-header"
                                            >
                                              <Box
                                                display="flex"
                                              >
                                                <Typography className={classes.headerPart}>{index + 1} - </Typography>
                                                <Box
                                                  display="flex"
                                                >
                                                  <Typography className={classes.headerPart}>Nombre de panneaux : {values.dimensions[index].nbPanels}</Typography>
                                                  <Typography className={classes.headerPart}>Sens de pose : {values.dimensions[index].direction === '' ? 'G' : 'D'}</Typography>
                                                  <Typography className={classes.headerPart}>Longueur totale du panneau : {values.dimensions[index].panelTotalLength}</Typography>
                                                </Box>
                                              </Box>
                                            </ExpansionPanelSummary>
                                            <Box
                                              display="flex"
                                              alignItems="stretch"
                                              p={2}
                                            >
                                              <Box
                                                display="flex"
                                                alignItems="center"
                                                borderRight={'3px solid'}
                                                className={classes.aside}
                                              >
                                                <Typography className={classes.asideTypo}>A</Typography>
                                              </Box>
                                              <Box
                                                display="flex"
                                                flexDirection="column"
                                                flexGrow="1"
                                              >
                                                <Box p={2}>
                                                  <TextField
                                                    label="Nombre de panneaux"
                                                    name={`${dimension}.nbPanels`}
                                                    variant="outlined"
                                                  />
                                                </Box>
                                                <Box 
                                                  p={2}
                                                >
                                                  <Typography>Sens de pose (G ou D)</Typography>
                                                  <Box
                                                    display="flex"
                                                    alignItems="center"
                                                  >
                                                    <Typography className={classes.switchLabel}>G</Typography>
                                                    <Switches
                                                      label=""
                                                      name={`${dimension}.direction`}
                                                      data={{
                                                        label: 'D',
                                                        value: dimension.direction
                                                      }}
                                                    />
                                                  </Box>
                                                </Box>
                                              </Box>
                                            </Box>

                                            {!isReinforcedProduct && <Box
                                              display="flex"
                                              alignItems="stretch"
                                              p={2}
                                            >
                                              <Box
                                                display="flex"
                                                alignItems="center"
                                                borderRight={'3px solid'}
                                                className={classes.aside}
                                              >
                                                <Typography className={classes.asideTypo}>B</Typography>
                                              </Box>
                                              <Box
                                                display="flex"
                                                flexDirection="column"
                                                flexGrow="1"
                                              >
                                                {[...Array(7)].map((e, i) => {
                                                  return <Box>
                                                    <Box p={2}>
                                                      <TextField
                                                        label={`Distance (D${i + 1})`}
                                                        helperText=" MAXI 1500mm"
                                                        name={`${dimension}.distances[${i}]`}
                                                        InputProps={{ max: 1500 }}
                                                        variant="outlined"
                                                      />
                                                    </Box>
                                                    {i < 6 && <Box p={2}><Typography className={`${classes.circle} ${classes.gray}`}>+</Typography></Box>}
                                                  </Box>
                                                })}
                                                {values.dimensions[index].errors.distances !== '' &&
                                                  <Box p={2}>
                                                    <Typography>
                                                      <WarningIcon /> {values.dimensions[index].errors.distances}
                                                    </Typography>
                                                  </Box>}
                                                <Box p={2}><Typography className={`${classes.circle} ${classes.gray}`}>=</Typography></Box>
                                              </Box>
                                            </Box>
                                            }
                                            {isReinforcedProduct &&
                                              <Box
                                                display="flex"
                                                alignItems="stretch"
                                                p={2}
                                              >
                                                <Box
                                                  display="flex"
                                                  alignItems="center"
                                                  borderRight={'3px solid'}
                                                  className={classes.aside}
                                                >
                                                  <Typography className={classes.asideTypo}>B</Typography>
                                                </Box>
                                                <Box
                                                  display="flex"
                                                  flexDirection="column"
                                                  flexGrow="1"
                                                >
                                                  <Box p={2}>
                                                    <TextField
                                                      label="LC : Longueur de caissson"
                                                      helperText="Maxi 6500 mm / Mini 1000 mm"
                                                      name={`${dimension}.boxLength`}
                                                      value={values.dimensions[index].boxLength}
                                                      variant="outlined"
                                                    />
                                                  </Box>
                                                  {values.dimensions[index].errors.boxLength !== '' &&
                                                    <Box p={2}>
                                                      <Typography className={classes.headerPart}>
                                                        <WarningIcon /> {values.dimensions[index].errors.boxLength}
                                                      </Typography>
                                                    </Box>}
                                                </Box>
                                              </Box>
                                            }
                                            <Box
                                              display="flex"
                                              alignItems="stretch"
                                              p={2}
                                            >
                                              <Box
                                                display="flex"
                                                alignItems="center"
                                                borderRight={'3px solid'}
                                                className={classes.aside}
                                              >
                                                <Typography className={classes.asideTypo}>C</Typography>
                                              </Box>
                                              <Box
                                                display="flex"
                                                flexDirection="column"
                                                flexGrow="1">
                                                {!isReinforcedProduct && <>
                                                  <Box p={2}>
                                                    <TextField
                                                      label="LC : Longueur de caissson"
                                                      helperText="Maxi 6500 mm / Mini 1000 mm"
                                                      name={`${dimension}.boxLength`}
                                                      value={values.dimensions[index].boxLength}
                                                      inputProps={{readOnly: true}}
                                                      variant="outlined"
                                                    />
                                                  </Box>
                                                  {values.dimensions[index].errors.boxLength !== '' &&
                                                    <Box p={2}>
                                                      <Typography>
                                                        <WarningIcon /> {values.dimensions[index].errors.boxLength}
                                                      </Typography>
                                                    </Box>}
                                                </>
                                                }
                                                <Box p={2}><Typography className={`${classes.circle} ${classes.red}`}>+</Typography></Box>
                                                <Box p={2}>
                                                  <TextField
                                                    label="R : Recouvrement"
                                                    name={`${dimension}.covering`}
                                                    variant="outlined"
                                                  />

                                                  {values.dimensions[index].covering < 200 &&
                                                    <Box p={2}>
                                                      <Typography className={classes.headerPart}>
                                                        <WarningIcon /> La longueur de recouvrement rentrée est inférieure à 200, la pose sera donc possible uniquement si le panneau termine à l'égout.
                                                      </Typography>
                                                    </Box>}

                                                  {values.dimensions[index].errors.covering !== '' &&
                                                    <Box p={2}>
                                                      <Typography className={classes.headerPart}>
                                                        <WarningIcon /> {values.dimensions[index].errors.covering}
                                                      </Typography>
                                                    </Box>}

                                                </Box>
                                                <Box p={2}><Typography className={`${classes.circle} ${classes.red}`}>=</Typography></Box>
                                                <Box p={2}>
                                                  <TextField
                                                    label="L : Longueur totale du panneau"
                                                    helperText="L = LC + R"
                                                    name={`${dimension}.panelTotalLength`}
                                                    inputProps={{readOnly: true}}
                                                    value={values.dimensions[index].panelTotalLength}
                                                    variant="outlined"
                                                  />

                                                  {values.dimensions[index].errors.panelTotalLength !== '' &&
                                                    <Box p={2}>
                                                      <Typography className={classes.headerPart}>
                                                        <WarningIcon /> {values.dimensions[index].errors.panelTotalLength}
                                                      </Typography>
                                                    </Box>}
                                                </Box>
                                              </Box>
                                            </Box>
                                          </ExpansionPanel>
                                          </>
                                        )}
                                      </FormSpy>

                                    )
                                    
                                  })}
                                </>
                              )
                            }}
                          </FieldArray>
                        </Box>
                        <Container maxWidth="lg">
                          <StepperButtons
                            submit={submit}
                            activeStep={activeStep}
                            projectId={projectId}
                            submitting={submitting}
                            shouldLink={pristine}
                            disabled={error}
                          />
                        </Container>
                      </form>
                    </Container>
                  </div>
                );
              }}
            />
          )}
        </MakeAsyncFunction>
      )}
    </>
  );
}