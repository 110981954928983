import { Grid } from "@material-ui/core";
import { Autocomplete, Radios, Switches, TextField } from "mui-rff";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usersActions } from "../../../../actions/users.actions";
import ImgUploader from "../../../../components/imgUploader";
import { AVAILABLES_TYPES } from "../../../../helpers/constante";

const formFields = [
  {
    mdSize: 6,
    xsSize: 12,
    field: <TextField label="Nom" name="name" margin="none" required />,
  },
  {
    mdSize: 6,
    xsSize: 12,
    field: <TextField label="Prénom" name="firstname" margin="none" required />,
  },
  {
    mdSize: 6,
    xsSize: 12,
    field: (
      <TextField
        label="Email"
        type="email"
        name="email"
        margin="none"
        required={true}
      />
    ),
  },
  {
    mdSize: 4,
    xsSize: 12,
    field: (
      <TextField
        label="N° de téléphone"
        type="tel"
        name="phone"
        margin="none"
        required={true}
      />
    ),
  },
  {
    mdSize: 4,
    xsSize: 12,
    field: (
      <TextField
        label="Société"
        name="company"
        margin="none"
        required={true}
      />
    ),
  },
  {
    mdSize: 4,
    xsSize: 12,
    field: (
      <TextField label="SIRET" name="siret" margin="none" required={false} />
    ),
  },
  {
    mdSize: 12,
    xsSize: 12,
    field: (
      <TextField
        label="Adresse"
        name="address"
        margin="none"
        required={false}
      />
    ),
  },
  {
    mdSize: 8,
    xsSize: 12,
    field: <TextField label="Ville" name="city" margin="none" required />,
  },
  {
    mdSize: 4,
    xsSize: 12,
    field: (
      <TextField
        label="Code postal"
        name="postCode"
        type="number"
        margin="none"
        required
      />
    ),
  },
  {
    mdSize: 6,
    xsSize: 12,
    field: (
      <Radios
        label="Type"
        name="type"
        color={"primary"}
        required={false}
        formControlProps={{ margin: "none" }}
        radioGroupProps={{ row: true }}
        data={AVAILABLES_TYPES}
      />
    ),
  },
  {
    mdSize: 6,
    xsSize: 12,
    field: (
      <Switches
        label="Grille tarifaire différente (appliquer un coefficient)"
        name="hasRatio"
        required={false}
        formControlProps={{ margin: "none" }}
        data={{
          label: 'Oui',
          value: true
        }} 
      />
    ),
  },
];

export default function ClientsForm(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(usersActions.fetchUsersIfNeeded());
  }, [dispatch]);

  let users = useSelector((state) => {
    return Object.values(state.users.all) || [];
  });

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} md={8} container spacing={2}>
        {formFields.map((item, idx) => (
          <Grid
            className={"mb-4"}
            item
            md={item.mdSize}
            xs={item.xsSize}
            key={idx}
          >
            {item.field}
          </Grid>
        ))}
        <Grid item md={6} xs={12}>
          <Autocomplete
            label="Manager"
            name="manager"
            options={users}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) =>
              `${option.firstName} ${option.lastName}`
            }
          />
        </Grid>
      </Grid>

      <Grid
        item
        md={4}
        xs={12}
        container
        alignItems={"center"}
        justify={"center"}
      >
        <Grid item lg={8} md={10} xs={8}>
          <ImgUploader
            image={props.image}
            beforeText="Ajouter un logo"
            afterText="Modifier le logo"
            onResult={(result) => props.setValue("image", result.id)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

ClientsForm.propTypes = {
  setValue: PropTypes.func.isRequired,
};
