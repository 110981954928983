import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Hidden,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { TextField } from "mui-rff";
import React, { useEffect } from "react";
import { Form } from "react-final-form";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MakeAsyncFunction from "react-redux-promise-listener";
import { Redirect, useParams } from "react-router-dom";
import { appActions } from "../../../actions/app.actions";
import { clientsActions } from "../../../actions/clients.actions";
import {
  ADD_PROJECT_ERROR,
  ADD_PROJECT_REQUEST,
  ADD_PROJECT_RESPONSE,
  EDIT_PROJECT_ERROR,
  EDIT_PROJECT_REQUEST,
  EDIT_PROJECT_RESPONSE,
  projectsActions,
} from "../../../actions/projects.actions";
import { usersActions } from "../../../actions/users.actions";
import ClientSelect from "../../../components/clients/ClientSelect";
import DetailsBloc from "../../../components/clients/DetailsBloc";
import StepperButtons from "../../../components/projects/StepperButtons";
import { StepperHeader } from "../../../components/projects/StepperHeader";
import { getProjectStep, useStyles } from "../../../helpers/project";
import { selectProject } from "../../../helpers/schema";
import { promiseListener } from "../../../helpers/store";

export default function Step1() {
  let { projectId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep] = React.useState(0);

  let submit;

  const isFetchingProject = useSelector((state) => {
    return state.projects.isFetching;
  });

  const clients = useSelector((state) => {
    return state.clients.all || [];
  }, shallowEqual);

  const project = useSelector((state) => {
    return selectProject(state, projectId) || {};
  }, shallowEqual);

  useEffect(() => {
    dispatch(clientsActions.fetchClientsIfNeeded());
    dispatch(usersActions.fetchUsersIfNeeded());
    if (projectId) {
      dispatch(appActions.setCurrentProject(projectId));
      dispatch(projectsActions.fetchProjectIfNeeded(projectId));
    }
    dispatch(appActions.setNextPage("/renov/projects/{currentProject}/step2"));
  }, [dispatch, projectId, isFetchingProject]);

  const filterFinal = (client) => client.type === "FINAL" || !client.type;

  if (!isFetchingProject && project.name && getProjectStep(project) < 1) {
    return (
      <Redirect to={`/renov/projects/${project.id}/step${getProjectStep(project)}`} />
    );
  }

  return (
    <>
      {isFetchingProject && projectId && !project.name && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <CircularProgress size={100} />
        </Box>
      )}
      {!isFetchingProject && projectId && !project.name && (
        <Alert severity="error">Projet non trouvé !</Alert>
      )}

      {(!projectId || project.name) && clients && (
        <MakeAsyncFunction
          listener={promiseListener}
          start={projectId ? EDIT_PROJECT_REQUEST : ADD_PROJECT_REQUEST}
          resolve={projectId ? EDIT_PROJECT_RESPONSE : ADD_PROJECT_RESPONSE}
          reject={projectId ? EDIT_PROJECT_ERROR : ADD_PROJECT_ERROR}
        >
          {(onSubmit) => (
            <Form
              initialValues={{
                name: project.name,
                client: project.client?.id,
                finalClient: project.finalClient?.id,
                customRef: project.customRef,
              }}
              mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }}
              onSubmit={(form) => {
                if (clients[form.client].type === "FINAL") {
                  form.finalClient = null;
                }
                onSubmit(form);
              }}
              render={({
                handleSubmit,
                submitting,
                values,
                pristine,
                form,
              }) => {
                submit = handleSubmit;
                return (
                  <div className={classes.root}>
                    <StepperHeader
                      submit={submit}
                      activeStep={activeStep}
                      projectId={projectId}
                      submitting={submitting}
                      shouldLink={pristine}
                      disabled={!values.name || !values.client}
                    />
                    <Container maxWidth="lg">
                      {Object.keys(clients).length == 0 && (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          flexGrow={1}
                          marginTop="2rem"
                          marginBottom="2rem"
                        >
                          <CircularProgress size={100} />
                        </Box>
                      )}

                      {Object.keys(clients).length > 0 && (
                        <Grid item xs={12}>
                          <Grid container spacing={3}>
                            <Grid className={"mb-4"} item xs={12}>
                              <form
                                className={classes.form}
                                onSubmit={handleSubmit}
                              >
                                <Box
                                  mt={2}
                                  mb={3}
                                  display="flex"
                                  flexDirection="column"
                                  flexGrow="1"
                                >
                                  <Grid container justify="center" spacing={3}>
                                    <Hidden mdDown>
                                      <Grid item lg={4} xs={0} />
                                    </Hidden>
                                    <Grid item lg={4} xs={12}>
                                      <TextField
                                        label="Nom du projet"
                                        variant="outlined"
                                        name="name"
                                        margin={"none"}
                                        required
                                      />
                                    </Grid>
                                    <Hidden mdDown>
                                      <Grid item lg={1} xs={0} />
                                    </Hidden>
                                    <Grid item lg={3} xs={8}>
                                      <TextField
                                        label="Référence interne"
                                        variant="outlined"
                                        name="customRef"
                                        margin={"none"}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Box mt={3}>
                                    <Grid container spacing={3}>
                                      <Grid item lg={6} xs={12}>
                                        <ClientSelect
                                          defaultClient={project?.client?.id}
                                          form={form}
                                          name="client"
                                          placeHolder="Client"
                                        />
                                        {clients[values.client] && (
                                          <DetailsBloc
                                            client={clients[values.client]}
                                          />
                                        )}
                                      </Grid>
                                      {clients[values.client]?.type !==
                                        "FINAL" && (
                                        <Grid item lg={6} xs={12}>
                                          <ClientSelect
                                            filterFunction={filterFinal}
                                            defaultClient={
                                              project?.finalClient?.id
                                            }
                                            form={form}
                                            name="finalClient"
                                            placeHolder="Client final"
                                          />
                                          {clients[values.finalClient] && (
                                            <DetailsBloc
                                              client={
                                                clients[values.finalClient]
                                              }
                                            />
                                          )}
                                        </Grid>
                                      )}
                                      {clients[values.client]?.type ===
                                        "FINAL" && (
                                        <Grid
                                          item
                                          container
                                          justify="center"
                                          alignItems="center"
                                          lg={6}
                                          xs={12}
                                        >
                                          <Box
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                          >
                                            <h3>
                                              Le client du projet est le client
                                              final
                                            </h3>
                                            <span>
                                              Le client séléctionné est un
                                              client final. Afin de définir un
                                              client en plus du client final,
                                              veuillez choisir un client via le
                                              sélécteur.
                                            </span>
                                          </Box>
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Box>
                                </Box>

                                <StepperButtons
                                  submit={submit}
                                  activeStep={activeStep}
                                  projectId={projectId}
                                  submitting={submitting}
                                  shouldLink={pristine}
                                  disabled={!values.name || !values.client}
                                />
                              </form>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Container>
                  </div>
                );
              }}
            />
          )}
        </MakeAsyncFunction>
      )}
    </>
  );
}
