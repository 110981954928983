import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Popover,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Fade from "@material-ui/core/Fade";
import InputAdornment from "@material-ui/core/InputAdornment";
import { GetApp, Help } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Alert from "@material-ui/lab/Alert";
import arrayMutators from "final-form-arrays";
import { TextField } from "mui-rff";
import React, { useEffect } from "react";
import { Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MakeAsyncFunction from "react-redux-promise-listener";
import { Redirect, useParams } from "react-router-dom";
import { appActions } from "../../../actions/app.actions";
import {
  EDIT_PROJECT_ERROR,
  EDIT_PROJECT_REQUEST,
  EDIT_PROJECT_RESPONSE,
  projectsActions,
} from "../../../actions/projects.actions";
import StepperButtons from "../../../components/projects/StepperButtons";
import { StepperHeader } from "../../../components/projects/StepperHeader";
import { getProjectStep, useStyles } from "../../../helpers/project";
import { promiseListener } from "../../../helpers/store";

const defaultSlopes = [
  {
    name: "versant A",
  },
];

export default function Step4() {
  let { projectId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep] = React.useState(3);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [tabsFields, setTabsFields] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  let submit;

  const handleModalCancel = () => {
    setModalOpen(false);
  };

  const handleModalAccept = () => {
    setModalOpen(false);
    if (tabsFields) {
      tabsFields.fields.remove(tabsFields.index);
    }
  };

  const handleClickOpen = (tabsFields) => {
    setModalOpen(true);
    setTabsFields(tabsFields);
  };

  const isFetchingProject = useSelector((state) => {
    return state.projects.isFetching;
  });

  const project = useSelector((state) => {
    return state.projects.all[projectId] || {};
  }, shallowEqual);

  const slopes = useSelector((state) => {
    return state.slopes.all;
  }, shallowEqual);

  let projectSlopes = [];

  if (project?.id && project.slopes.length) {
    project.slopes.forEach((slopeId) => {
      const slope = slopes[slopeId];
      projectSlopes.push({
        id: slope.id,
        name: slope.name,
        width: slope.width,
        height: slope.height,
      });
    });
  } else {
    projectSlopes = defaultSlopes;
  }

  useEffect(() => {
    dispatch(projectsActions.fetchProjectIfNeeded(projectId));
    dispatch(appActions.setNextPage("/renov/projects/{currentProject}/step5"));
    dispatch(appActions.setCurrentProject(projectId));
  }, [dispatch, projectId, isFetchingProject]);

  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const emptySlopes = (slopes) => {
    let empty = false;
    slopes.forEach((slope) => {
      if (
        !slope.height ||
        slope.height < 0 ||
        !slope.width ||
        slope.width < 0
      ) {
        empty = true;
      }
    });
    return empty;
  };

  if (!isFetchingProject && project.name && getProjectStep(project) < 3) {
    return (
      <Redirect to={`/renov/projects/${project.id}/step${getProjectStep(project)}`} />
    );
  }

  return (
    <>
      {isFetchingProject && !project.name && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <CircularProgress size={100} />
        </Box>
      )}
      {!isFetchingProject && !project.name && (
        <Alert severity="error">Projet non trouvée !</Alert>
      )}

      {project.name && (
        <MakeAsyncFunction
          listener={promiseListener}
          start={EDIT_PROJECT_REQUEST}
          resolve={EDIT_PROJECT_RESPONSE}
          reject={EDIT_PROJECT_ERROR}
        >
          {(onSubmit) => (
            <Form
              initialValues={{ slopes: projectSlopes }}
              initialValuesEqual={() => true}
              onSubmit={(form) => {
                form.slopes.forEach((slope, id) => {
                  if (typeof slope.width === "string") {
                    form.slopes[id].width = parseFloat(form.slopes[id].width);
                  }
                  if (typeof slope.height === "string") {
                    form.slopes[id].height = parseFloat(form.slopes[id].height);
                  }
                });

                return onSubmit(form);
              }}
              mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
                ...arrayMutators,
              }}
              render={({
                handleSubmit,
                submitting,
                values,
                pristine,
                form: {
                  mutators: { push },
                },
              }) => {
                submit = handleSubmit;
                return (
                  <div className={classes.root}>
                    <StepperHeader
                      submit={submit}
                      activeStep={activeStep}
                      projectId={parseInt(projectId)}
                      submitting={submitting}
                      shouldLink={pristine}
                      disabled={
                        values.slopes.length === 0 || emptySlopes(values.slopes)
                      }
                    />
                    <Container maxWidth="lg">
                      <Grid className={"mb-4"} item xs={12}>
                        <Dialog
                          open={modalOpen}
                          onClose={handleModalCancel}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {"Voulez vous supprimer ce versant ?"}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              Cette action est irréversible
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleModalCancel} color="primary">
                              Annuler
                            </Button>
                            <Button
                              onClick={handleModalAccept}
                              color="primary"
                              autoFocus
                            >
                              Supprimer
                            </Button>
                          </DialogActions>
                        </Dialog>

                        <Box m={1}>
                          <Grid container>
                            <Grid item>
                              <Button
                                aria-describedby={id}
                                variant="contained"
                                color="primary"
                                onClick={handleClickPopover}
                              >
                                <Help /> <Box ml={1}>Aide</Box>
                              </Button>
                              <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClosePopover}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                              >
                                <Box p={2}>
                                  <Box textAlign="center">
                                    <img
                                      alt="notice utilitsation de l'outil"
                                      src="/notices/onduline_schema_slope.png"
                                    />
                                  </Box>
                                  <Box textAlign="center" m={1}>
                                    <Button
                                      href="/notices/prise_cotes.pdf"
                                      color="primary"
                                      variant="contained"
                                      download
                                    >
                                      <GetApp /> Notice PDF
                                    </Button>
                                  </Box>
                                </Box>
                              </Popover>
                            </Grid>
                          </Grid>
                        </Box>

                        <form className={classes.form} onSubmit={handleSubmit}>
                          <Box mt={2}>
                            <FieldArray name="slopes">
                              {({ fields }) =>
                                fields.map((name, index) => (
                                  <Fade key={name} in={true}>
                                    <ExpansionPanel defaultExpanded>
                                      <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                      >
                                        <Typography className={classes.heading}>
                                          {values.slopes[index].name}
                                        </Typography>
                                      </ExpansionPanelSummary>
                                      <ExpansionPanelDetails>
                                        <Box
                                          display="flex"
                                          flexGrow={1}
                                          flexDirection="column"
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item lg={4} md={6} xs={12}>
                                              <TextField
                                                variant="outlined"
                                                label="Nom du versant"
                                                name={`${name}.name`}
                                                required
                                              />
                                            </Grid>
                                            <Grid item lg={4} md={6} xs={12}>
                                              <TextField
                                                variant="outlined"
                                                label="Longueur du bâtiment"
                                                name={`${name}.width`}
                                                type="Number"
                                                InputProps={{
                                                  step: "0.01",
                                                  startAdornment: (
                                                    <InputAdornment position="start">
                                                      ml
                                                    </InputAdornment>
                                                  ),
                                                }}
                                              />
                                            </Grid>
                                            <Grid item lg={4} md={6} xs={12}>
                                              <TextField
                                                variant="outlined"
                                                label="Longueur du rampant"
                                                name={`${name}.height`}
                                                type="Number"
                                                inputProps={{ step: "0.01" }}
                                                InputProps={{
                                                  startAdornment: (
                                                    <InputAdornment position="start">
                                                      ml
                                                    </InputAdornment>
                                                  ),
                                                }}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </ExpansionPanelDetails>
                                      <Divider />
                                      <ExpansionPanelActions>
                                        <Box
                                          flexGrow={1}
                                          display="flex"
                                          justifyContent="space-between"
                                        >
                                          <Box ml={1}>
                                            {values.slopes[index].width &&
                                              values.slopes[index].height && (
                                                <Typography variant="overline">
                                                  Superficie :{" "}
                                                  {(
                                                    values.slopes[index].width *
                                                    values.slopes[index].height
                                                  ).toFixed(2)}{" "}
                                                  m<sup>2</sup>
                                                </Typography>
                                              )}
                                          </Box>
                                          <Button
                                            size="small"
                                            color="primary"
                                            disabled={fields.length === 1}
                                            onClick={() =>
                                              handleClickOpen({
                                                fields,
                                                index,
                                              })
                                            }
                                          >
                                            Supprimer le versant
                                          </Button>
                                        </Box>
                                      </ExpansionPanelActions>
                                    </ExpansionPanel>
                                  </Fade>
                                ))
                              }
                            </FieldArray>
                          </Box>

                          <ExpansionPanel defaultExpanded>
                            <ExpansionPanelActions>
                              <Box
                                flexGrow={1}
                                display="flex"
                                justifyContent="center"
                              >
                                <Box ml={1}>
                                  <Typography variant="overline">
                                    Superficie totale:{" "}
                                    {Math.round(
                                      values.slopes.reduce(
                                        (acc, val) =>
                                          acc +
                                          parseFloat(
                                            (val.width ? val.width : 0) *
                                            (val.height ? val.height : 0)
                                          ),
                                        0
                                      )
                                    )}
                                    m²
                                  </Typography>
                                </Box>
                              </Box>
                            </ExpansionPanelActions>
                          </ExpansionPanel>

                          <Box mb={4} mt={2}>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() =>
                                push("slopes", {
                                  name: "versant " + String.fromCharCode(values.slopes.length + 65), // ASCII 65 = 'A'
                                })
                              }
                            >
                              Ajouter un versant
                            </Button>
                          </Box>

                          <StepperButtons
                            activeStep={activeStep}
                            submit={submit}
                            projectId={projectId}
                            submitting={submitting}
                            shouldLink={pristine}
                            disabled={
                              values.slopes.length === 0 ||
                              emptySlopes(values.slopes)
                            }
                          />
                        </form>
                      </Grid>
                    </Container>
                  </div>
                );
              }}
            />
          )}
        </MakeAsyncFunction>
      )}
    </>
  );
}
