import {
  Box,
  Button,
  CardMedia,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Link,
  Paper,
  Popover,
  Slider,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Close,
  GetApp,
  Help,
  InsertDriveFile,
  Lock,
  Sync,
} from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import Alert from "@material-ui/lab/Alert";
import arrayMutators from "final-form-arrays";
import { TextField } from "mui-rff";
import React, { Fragment, useEffect, useState } from "react";
import { Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { Document, Page } from "react-pdf";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MakeAsyncFunction from "react-redux-promise-listener";
import { Redirect, useParams } from "react-router-dom";
import { appActions } from "../../../actions/app.actions";
import {
  closeSnackbar as closeSnackbarAction,
  enqueueSnackbar as enqueueSnackbarAction,
} from "../../../actions/notifications.actions";
import {
  EDIT_PROJECT_ERROR,
  EDIT_PROJECT_REQUEST,
  EDIT_PROJECT_RESPONSE,
  projectsActions,
} from "../../../actions/projects.actions";
import {
  EDIT_QUOTATION_ERROR,
  EDIT_QUOTATION_REQUEST,
  EDIT_QUOTATION_RESPONSE,
} from "../../../actions/quotations.actions";
import StepperButtons from "../../../components/projects/StepperButtons";
import { StepperHeader } from "../../../components/projects/StepperHeader";
import UploadArea from "../../../components/projects/UploadArea";
import SendMailModal from "../../../components/SendMailModal";
import { history } from "../../../helpers/history";
import { getProjectStep, useStyles } from "../../../helpers/project";
import { selectProject } from "../../../helpers/schema";
import { promiseListener } from "../../../helpers/store";
import { getUser } from "../../../helpers/user";

export default function Step9() {
  const theme = useTheme();
  const isScreenMd = useMediaQuery(theme.breakpoints.down("md"));
  let { projectId } = useParams();
  let submit;
  let submitMargin;

  const classes = useStyles();

  const dispatch = useDispatch();
  const [activeStep] = React.useState(8);
  const [quotationPdf, setQuotationPdf] = useState({});
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingPdfCustomer, setLoadingPdfCustomer] = useState(false);
  const [open, setOpen] = useState(false);
  const [pdfPagesNum, setPdfPagesNum] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [outdated, setOutdated] = useState(true);
  const [generatingQuotation, setGeneratingQuotation] = useState(false);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [displayAlertOutdated, setDisplayAlertOutdated] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showConfirmRecalculateModal, setShowConfirmRecalculateModal] =
    useState(false);
  const [showSendMailModal, setShowSendMailModal] = useState(false);

  const isFetchingProject = useSelector((state) => {
    return state.projects.isFetching;
  });

  const isGeneratingQuotation = useSelector((state) => {
    return state.projects.isGeneratingQuotation;
  });

  const project = useSelector((state) => {
    return selectProject(state, projectId) || {};
  }, shallowEqual);

  const product = project.productVariant?.product;

  const formatterEuro = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  });

  useEffect(() => {
    dispatch(appActions.setCurrentProject(projectId));
    dispatch(projectsActions.fetchProjectIfNeeded(projectId));
    dispatch(appActions.setNextPage("/renov/projects/{currentProject}/step9"));
  }, [dispatch, projectId, isFetchingProject]);

  const isUpdatingProject = useSelector((state) => {
    return state.projects.isUpdating;
  });

  let quotation = project?.lastQuotation || {};

  if (
    (quotation?.updatedAt &&
      new Date(quotation?.updatedAt).getTime() !==
      new Date(initialValues?.updatedAt).getTime()) ||
    outdated
  ) {
    const customFields = quotation?.quotationFields?.filter(
      (e) => e.category === "Personnalisés"
    );

    const accessoriesFieldsArray = quotation?.quotationFields
      ?.filter((e) => e.category === "Accessoires")
      ?.sort((a, b) => a.subCategory?.localeCompare(b.subCategory));

    const accessoriesFields = accessoriesFieldsArray?.sort().map((field) => ({
      accessory: project.accessories.find(
        (projectAccessory) => projectAccessory.accessory.name === field.naming
      )?.accessory,
      ...field,
    }));

    const platesFields = quotation?.quotationFields
      ?.filter((e) => e.category === "Plaques")
      .sort((a, b) => {
        if (a.nomenclature === undefined || b.nomenclature === undefined) {
          return 0;
        } else {
          return a.nomenclature.name.localeCompare(b.nomenclature.name);
        }
      });

    const transportField = quotation?.quotationFields?.find(
      (e) => e.category === "Transport"
    );

    setInitialValues({
      updatedAt: quotation.updatedAt,
      customFields,
      accessoriesFields,
      platesFields,
      transportField,
    });

    setOutdated(false);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const regenerateQuotation = () => {
    dispatch(projectsActions.generateQuotation(projectId)).then(() => {
      setShowConfirmRecalculateModal(false);
      dispatch(closeSnackbarAction("step9_outdatedQuotationGeneration"));
    });
  };

  function onDocumentLoadSuccess({ numPages }) {
    setPdfPagesNum(numPages);
  }

  if (
    project.id &&
    !project.lastQuotation &&
    !isGeneratingQuotation &&
    getProjectStep(project) >= 8
  ) {
    regenerateQuotation();
  }

  if (quotation?.outdated && !displayAlertOutdated) {
    setDisplayAlertOutdated(true);
    dispatch(
      enqueueSnackbarAction({
        message:
          'Ce projet a été modifié. Ce récapitulatif est donc probablement périmé. Cliquez sur "Recalculer" pour relancer le calcul.',
        options: {
          key: "step9_outdatedQuotationGeneration",
          autoHideDuration: null,
          variant: "warning",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          action: (key) => (
            <React.Fragment>
              <Button
                variant="contained"
                color={"primary"}
                onClick={() => {
                  regenerateQuotation();
                  dispatch(closeSnackbarAction(key));
                }}
                disabled={generatingQuotation}
              >
                {!generatingQuotation && (
                  <>
                    <Sync /> Regénérer
                  </>
                )}
                {generatingQuotation && <CircularProgress size={20} />}
              </Button>
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={() => dispatch(closeSnackbarAction(key))}
              >
                <Close />
              </IconButton>
            </React.Fragment>
          ),
        },
      })
    );
  }

  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? "simple-popover" : undefined;

  if (!isFetchingProject && project.name && getProjectStep(project) < 8) {
    return (
      <Redirect to={`/renov/projects/${project.id}/step${getProjectStep(project)}`} />
    );
  }

  return (
    <>
      {isFetchingProject && !project.name && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <CircularProgress size={100} />
        </Box>
      )}
      {!isFetchingProject && !project.name && (
        <Alert severity="error">Projet non trouvé !</Alert>
      )}

      {!isFetchingProject && project.name && (
        <MakeAsyncFunction
          listener={promiseListener}
          start={EDIT_QUOTATION_REQUEST}
          resolve={EDIT_QUOTATION_RESPONSE}
          reject={EDIT_QUOTATION_ERROR}
        >
          {(onSubmit) => (
            <Form
              initialValues={initialValues}
              onSubmit={(form) => {
                form.accessoriesFields.forEach((field, index) => {
                  if (typeof field.unitPrice === "string") {
                    form.accessoriesFields[index].unitPrice = parseFloat(
                      form.accessoriesFields[index].unitPrice
                    );
                  }

                  if (typeof field.quantity === "string") {
                    form.accessoriesFields[index].quantity = parseFloat(
                      form.accessoriesFields[index].quantity
                    );
                  }

                  if (typeof field.basePrice === "string") {
                    form.accessoriesFields[index].basePrice = parseFloat(
                      form.accessoriesFields[index].basePrice
                    );
                  }
                });

                form.customFields.forEach((field, index) => {
                  if (
                    !field.quantity &&
                    !field.unit &&
                    !field.unitPrice &&
                    !field.naming
                  ) {
                    form.customFields.pop(index);
                  }

                  if (typeof field.basePrice === "string") {
                    form.customFields[index].basePrice = parseFloat(
                      form.customFields[index].basePrice
                    );
                  }

                  if (typeof field.quantity === "string") {
                    form.customFields[index].quantity = parseFloat(
                      form.customFields[index].quantity
                    );
                  }
                });

                form.platesFields.forEach((field, index) => {
                  if (typeof field.unitPrice === "string") {
                    form.platesFields[index].unitPrice = parseFloat(
                      form.platesFields[index].unitPrice
                    );
                  }

                  if (typeof field.quantity === "string") {
                    form.platesFields[index].quantity = parseFloat(
                      form.platesFields[index].quantity
                    );
                  }

                  if (typeof field.length === "string") {
                    form.platesFields[index].length = parseFloat(
                      form.platesFields[index].length
                    );
                  }
                });

                if (form?.transportField?.basePrice) {
                  form.transportField.basePrice = parseFloat(
                    form.transportField.basePrice
                  );
                }

                const quotationFields = form?.transportField?.basePrice
                  ? [
                    ...form.accessoriesFields,
                    ...form.customFields,
                    ...form.platesFields,
                    form.transportField,
                  ]
                  : [
                    ...form.accessoriesFields,
                    ...form.customFields,
                    ...form.platesFields,
                  ];

                return onSubmit({
                  id: quotation.id,
                  quotationFields: quotationFields.map((field) => ({
                    ...field,
                    accessory: field.accessory?.id,
                    slope: field.slope?.id,
                  })),
                });
              }}
              mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
                addRow: (args, state, utils) => {
                  utils.changeValue(state, "customFields", () =>
                    state.customFields.push({
                      designation: null,
                      category: "Personnalisés",
                      unit: "Personnalisés",
                      quantity: 0,
                      price: 0,
                    })
                  );
                },
                ...arrayMutators,
              }}
              render={({
                handleSubmit,
                submitting,
                values,
                form,
                pristine,
                form: {
                  mutators: { addRow },
                },
              }) => {
                submit = handleSubmit;

                return (
                  <div className={classes.root}>
                    <StepperHeader
                      last
                      submit={submit}
                      activeStep={activeStep}
                      projectId={parseInt(projectId)}
                      submitting={submitting}
                      shouldLink={pristine}
                    />
                    <Container maxWidth="lg">
                      <Grid className={"mb-4"} item xs={12}>
                        <Box
                          mt={2}
                          mb={4}
                          display="flex"
                          justifyContent="center"
                        >
                          <Chip
                            label={project.name + " (#" + projectId + ")"}
                            color="primary"
                            size="medium"
                            variant={"outlined"}
                          />
                        </Box>

                        <Grid container spacing={2} justify="center">
                          <Grid item xs={12} md={6}>
                            <Paper>
                              <Box display="flex" flexDirection="column">
                                <Box
                                  mt={1}
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <Typography variant="body1">
                                    Produit
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    m={2}
                                    className={classes.boxSize}
                                  >
                                    <CardMedia
                                      className={
                                        project.productVariant.image
                                          ? classes.image
                                          : classes.bgLightGrey
                                      }
                                      image={
                                        process.env.REACT_APP_API_URL +
                                        project.productVariant?.product?.image
                                          ?.contentUrl
                                      }
                                      title={product.name}
                                      alt={product.name}
                                    />
                                  </Box>
                                  <Box
                                    display="flex"
                                    flexGrow={1}
                                    justifyContent="center"
                                    alignItems="start"
                                    flexDirection="column"
                                    m={2}
                                  >
                                    <strong>
                                      {product.name} ({product.reference})
                                    </strong>
                                    <p className={classes.mt1}>
                                      {project.productColor.name}
                                    </p>
                                  </Box>
                                </Box>
                              </Box>
                            </Paper>
                          </Grid>

                          {/* ********* Isolant ********* */}
                          <Grid item xs={12} md={6}>
                            <Paper>
                              <Box display="flex" flexDirection="column">
                                <Box
                                  mt={1}
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <Typography variant="body1">
                                    Isolant
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    m={2}
                                    className={classes.boxSize}
                                  >
                                    <CardMedia
                                      className={
                                        project.insulation.image
                                          ? classes.image
                                          : classes.bgLightGrey
                                      }
                                      image={
                                        process.env.REACT_APP_API_URL +
                                        project.insulation?.image?.contentUrl
                                      }
                                      title={project.insulation.name}
                                      alt={project.insulation.name}
                                    />
                                  </Box>
                                  <Box
                                    display="flex"
                                    flexGrow={1}
                                    justifyContent="center"
                                    alignItems="center"
                                    flexDirection="column"
                                    m={2}
                                  >
                                    <strong>
                                      {project.insulation.name} (
                                      {project.insulation.reference})
                                    </strong>
                                  </Box>
                                </Box>
                              </Box>
                            </Paper>
                          </Grid>
                        </Grid>

                        <Box mt={2} mb={2}>
                          <Divider m={3} />
                        </Box>

                        <Box textAlign="center" m={1}>
                          <Typography variant="h6">Versants</Typography>
                        </Box>

                        <Grid className={"mb-4"} container spacing={2}>
                          {project.slopes.map((slope, index) => (
                            <Grid item xs={6} md={3} key={index}>
                              <Paper>
                                <Box display="flex" flexDirection="column">
                                  <Link
                                    style={{
                                      cursor: "pointer",
                                      color: "#000",
                                    }}
                                    onClick={() =>
                                      history.push(
                                        `/renov/projects/${projectId}/step7`
                                      )
                                    }
                                  >
                                    <Box
                                      mt={1}
                                      display="flex"
                                      justifyContent="center"
                                    >
                                      <Typography variant="body1">
                                        {slope.name}
                                      </Typography>
                                    </Box>
                                  </Link>
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                  >
                                    <Box
                                      display="flex"
                                      flexGrow={1}
                                      justifyContent="center"
                                      alignItems="center"
                                      flexDirection="column"
                                      m={2}
                                    >
                                      <Chip
                                        label={`${slope.realArea}m²`}
                                        color="primary"
                                        size="medium"
                                        variant={"outlined"}
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              </Paper>
                            </Grid>
                          ))}

                          <Grid item xs={6} md={3}>
                            <Paper>
                              <Box display="flex" flexDirection="column">
                                <Box
                                  mt={1}
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <Typography variant="body1">
                                    Superficie totale
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <Box
                                    display="flex"
                                    flexGrow={1}
                                    justifyContent="center"
                                    alignItems="center"
                                    flexDirection="column"
                                    m={2}
                                  >
                                    <Chip
                                      label={`${project.totalAreaFinal}m²`}
                                      color="primary"
                                      size="medium"
                                      variant={"outlined"}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </Paper>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Box mt={2} mb={2}>
                        <Divider m={3} />
                      </Box>

                      <form className={classes.form} onSubmit={handleSubmit}>
                        <Grid container className={"mb-4"} item xs={12}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            flexGrow={1}
                          >
                            <Box textAlign="center">
                              <Typography variant="h6">Plaques</Typography>
                            </Box>

                            <FieldArray name="platesFields">
                              {({ fields }) =>
                                fields.map((field, index) => (
                                  <Fragment key={index}>
                                    <Box
                                      key={index}
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="center"
                                      justifyContent="center"
                                      flexGrow={1}
                                    >
                                      <Grid container>
                                        <Grid item lg={1} md={12}>
                                          <Box p={1}>
                                            <TextField
                                              size="small"
                                              variant="outlined"
                                              label={"Colis"}
                                              name={`${field}.packageName`}
                                            />
                                          </Box>
                                        </Grid>
                                        <Grid item lg={3} md={12}>
                                          <Box p={1}>
                                            <TextField
                                              disabled
                                              size="small"
                                              variant="filled"
                                              label={"Designation"}
                                              name={`${field}.naming`}
                                            />
                                          </Box>
                                        </Grid>
                                        <Grid container item lg={8} md={12}>
                                          <Grid container item md={4}>
                                            <Grid item md={7} xs={6}>
                                              <Box p={1}>
                                                <TextField
                                                  size="small"
                                                  variant="outlined"
                                                  type="number"
                                                  label={"Long."}
                                                  name={`${field}.length`}
                                                  InputProps={{
                                                    endAdornment: "ml",
                                                  }}
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid item md={5} xs={6}>
                                              <Box p={1}>
                                                <TextField
                                                  size="small"
                                                  variant="outlined"
                                                  type="number"
                                                  label={"Quantité"}
                                                  name={`${field}.quantity`}
                                                />
                                              </Box>
                                            </Grid>
                                          </Grid>

                                          <Grid item md={8} xs={12}>
                                            <Grid container>
                                              <Grid item md={3} xs={12}>
                                                <Box p={1}>
                                                  <TextField
                                                    disabled
                                                    size="small"
                                                    variant="filled"
                                                    type="number"
                                                    label={"Superficie"}
                                                    name={`${field}.surface`}
                                                    InputProps={{
                                                      endAdornment: "m²",
                                                    }}
                                                  />
                                                </Box>
                                              </Grid>
                                              <Grid item md={3} xs={12}>
                                                <Box p={1}>
                                                  <TextField
                                                    disabled
                                                    size="small"
                                                    variant="filled"
                                                    type="string"
                                                    label={"Versant"}
                                                    name={`${field}.slope.name`}
                                                  />
                                                </Box>
                                              </Grid>
                                              <Grid item md={3} xs={12}>
                                                <Box p={1}>
                                                  <TextField
                                                    disabled
                                                    size="small"
                                                    type="number"
                                                    variant="filled"
                                                    label={"Prix/u"}
                                                    name={`${field}.price`}
                                                    InputProps={{
                                                      endAdornment: "€",
                                                    }}
                                                  />
                                                </Box>
                                              </Grid>
                                              <Grid item md={3} xs={12}>
                                                <Box p={1}>
                                                  <TextField
                                                    disabled
                                                    size="small"
                                                    type="number"
                                                    variant="filled"
                                                    label={"Prix total"}
                                                    name={`${field}.totalPrice`}
                                                    InputProps={{
                                                      endAdornment: "€",
                                                    }}
                                                  />
                                                </Box>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                    {isScreenMd && <Divider />}
                                  </Fragment>
                                ))
                              }
                            </FieldArray>

                            <Divider />

                            <Box textAlign="center" m={1}>
                              <Typography variant="h6">Accessoires</Typography>
                            </Box>

                            <FieldArray name="accessoriesFields">
                              {({ fields }) =>
                                fields.map((field, index) => {
                                  return (
                                    <Box
                                      key={index}
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="center"
                                      justifyContent="center"
                                      flexGrow={1}
                                    >
                                      <Grid container alignItems="center">
                                        <Grid item lg={6} xs={12}>
                                          <Box p={1}>
                                            <TextField
                                              disabled
                                              size="small"
                                              variant="filled"
                                              label={"Designation"}
                                              name={`${field}.naming`}
                                            />
                                          </Box>
                                        </Grid>

                                        <Grid item lg={1} xs={6}>
                                          <Box p={1}>
                                            <TextField
                                              variant="outlined"
                                              size="small"
                                              type="number"
                                              label={"Quantité"}
                                              name={`${field}.quantity`}
                                            />
                                          </Box>
                                        </Grid>

                                        <Grid item lg={5} xs={12}>
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            flexWrap="wrap"
                                          >
                                            <Grid item lg={3} xs={12}>
                                              <Box p={1}>
                                                <TextField
                                                  size="small"
                                                  type="number"
                                                  variant="outlined"
                                                  label={"Prix/u"}
                                                  name={`${field}.basePrice`}
                                                  InputProps={{
                                                    endAdornment: "€",
                                                  }}
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid item lg={4} xs={12}>
                                              <Box p={1}>
                                                <TextField
                                                  disabled
                                                  size="small"
                                                  type="number"
                                                  variant="filled"
                                                  label={"Prix/u avec marge"}
                                                  name={`${field}.price`}
                                                  InputProps={{
                                                    endAdornment: "€",
                                                  }}
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid item lg={4} xs={12}>
                                              <Box p={1}>
                                                <TextField
                                                  disabled
                                                  size="small"
                                                  type="number"
                                                  variant="filled"
                                                  label={"Prix total"}
                                                  name={`${field}.totalPrice`}
                                                  InputProps={{
                                                    endAdornment: "€",
                                                  }}
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid item lg={1} xs={12}>
                                              <Box p={1}>
                                                {fields.value[index].accessory
                                                  ?.pdf?.contentUrl && (
                                                    <a
                                                      href={
                                                        process.env
                                                          .REACT_APP_API_URL +
                                                        fields.value[index]
                                                          .accessory?.pdf
                                                          ?.contentUrl
                                                      }
                                                      target="_blank"
                                                      rel="noopener"
                                                      download
                                                    >
                                                      <InsertDriveFile
                                                        color="primary"
                                                        fontSize="medium"
                                                      />
                                                    </a>
                                                  )}
                                              </Box>
                                            </Grid>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  );
                                })
                              }
                            </FieldArray>

                            <Divider />

                            <Box
                              display="flex"
                              justifyContent="center"
                              textAlign="center"
                              m={1}
                            >
                              <Box
                                display="flex"
                                justifyContent="flex-end"
                                pr={2}
                                flexBasis={0}
                                flexGrow={1}
                              >
                                <Button
                                  aria-describedby={id}
                                  variant="contained"
                                  color="primary"
                                  onClick={handleClickPopover}
                                >
                                  <Help /> <Box ml={1}>Aide</Box>
                                </Button>
                              </Box>
                              <Typography variant="h6">
                                Frais de transport:
                              </Typography>
                              <Box flexBasis={0} flexGrow={1}></Box>
                              <Popover
                                id={id}
                                open={popoverOpen}
                                anchorEl={anchorEl}
                                onClose={handleClosePopover}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                              >
                                <Box p={2}>
                                  <Box textAlign="center">
                                    <span>
                                      Les frais de transports sont calculés à
                                      partir du client final si celui-ci est
                                      renseigné. Dans le cas contraire, ils sont
                                      calculés à partir de l'adresse du client
                                    </span>
                                  </Box>
                                </Box>
                              </Popover>
                            </Box>

                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="center"
                              flexGrow={1}
                            >
                              <Grid item md={4} xs={4} offset={4}>
                                <Box p={1}>
                                  <TextField
                                    disabled={!initialValues?.transportField}
                                    size="small"
                                    type="number"
                                    inputProps={{
                                      min: 0,
                                    }}
                                    required
                                    variant="outlined"
                                    label={"Transport"}
                                    name={"transportField.basePrice"}
                                    InputProps={{
                                      endAdornment:
                                        initialValues?.transportField ? (
                                          "€"
                                        ) : (
                                          <Tooltip
                                            title={
                                              <>
                                                <Typography variant="overline">
                                                  Les frais de transport sont
                                                  indisponibles
                                                </Typography>
                                              </>
                                            }
                                          >
                                            <Lock color="primary" />
                                          </Tooltip>
                                        ),
                                    }}
                                  />
                                </Box>
                              </Grid>
                            </Box>

                            <Divider />

                            <Box textAlign="center" m={1}>
                              <Typography variant="h6">
                                Champs libres
                              </Typography>
                              <Button
                                variant="contained"
                                color={"primary"}
                                onClick={() => {
                                  form.mutators.setValue("customFields", [
                                    ...values.customFields,
                                    {
                                      category: "Personnalisés",
                                      unit: "",
                                      naming: "",
                                      quantity: 0,
                                      basePrice: 0,
                                    },
                                  ]);
                                }}
                              >
                                +
                              </Button>
                            </Box>

                            <FieldArray name="customFields">
                              {({ fields }) =>
                                fields.map((field, index) => (
                                  <Box
                                    key={index}
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    flexGrow={1}
                                  >
                                    <Grid container>
                                      <Grid item md={6} xs={12}>
                                        <Box p={1}>
                                          <TextField
                                            variant="outlined"
                                            size="small"
                                            label={"Designation"}
                                            name={`${field}.naming`}
                                          />
                                        </Box>
                                      </Grid>
                                      <Grid item md={6} xs={12}>
                                        <Grid container>
                                          <Grid item md={3} xs={3}>
                                            <Box p={1}>
                                              <TextField
                                                type="number"
                                                variant="outlined"
                                                size="small"
                                                label={"Quantité"}
                                                name={`${field}.quantity`}
                                              />
                                            </Box>
                                          </Grid>
                                          <Grid item md={3} xs={3}>
                                            <Box p={1}>
                                              <TextField
                                                variant="outlined"
                                                size="small"
                                                label={"Unité"}
                                                name={`${field}.unit`}
                                              />
                                            </Box>
                                          </Grid>
                                          <Grid item md={3} xs={3}>
                                            <Box p={1}>
                                              <TextField
                                                type="number"
                                                variant="outlined"
                                                size="small"
                                                label={"Prix/u"}
                                                name={`${field}.basePrice`}
                                              />
                                            </Box>
                                          </Grid>
                                          <Grid item md={3} xs={3}>
                                            <Box p={1}>
                                              <TextField
                                                disabled
                                                type="number"
                                                value={
                                                  parseFloat(
                                                    values.customFields[index]
                                                      .quantity
                                                  ) *
                                                  parseFloat(
                                                    values.customFields[index]
                                                      .basePrice
                                                  )
                                                }
                                                variant="filled"
                                                size="small"
                                                label={"Prix total"}
                                                name={`${field}.total`}
                                              />
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Button
                                      variant="contained"
                                      color={"primary"}
                                      onClick={() => {
                                        let newFields =
                                          values.customFields.slice(0);
                                        newFields.splice(index, 1);
                                        form.mutators.setValue(
                                          "customFields",
                                          newFields
                                        );
                                      }}
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </Box>
                                ))
                              }
                            </FieldArray>
                          </Box>
                        </Grid>

                        <Box mt={2} mb={2}>
                          <Divider m={3} />
                        </Box>

                        <MakeAsyncFunction
                          listener={promiseListener}
                          start={EDIT_PROJECT_REQUEST}
                          resolve={EDIT_PROJECT_RESPONSE}
                          reject={EDIT_PROJECT_ERROR}
                        >
                          {(onSubmit) => (
                            <Form
                              initialValues={{
                                priceMargin: project.priceMargin,
                              }}
                              onSubmit={async (form) => {
                                await submit();
                                await onSubmit(form);
                                setOutdated(true);
                              }}
                              mutators={{
                                setValue: (
                                  [field, value],
                                  state,
                                  { changeValue }
                                ) => {
                                  changeValue(state, field, () => value);
                                },
                                ...arrayMutators,
                              }}
                              render={({
                                handleSubmit,
                                submitting,
                                values,
                                form,
                                pristine: pristineMargin,
                                form: {
                                  mutators: { addRow },
                                },
                              }) => {
                                submitMargin = handleSubmit;
                                return (
                                  <div className={classes.root}>
                                    <Grid item xs={12} md={12}>
                                      <Box
                                        display="flex"
                                        flexDirection="column"
                                      >
                                        <Box
                                          mt={1}
                                          mb={5}
                                          display="flex"
                                          justifyContent="center"
                                        >
                                          <Typography variant="h6">
                                            Marge appliquée
                                          </Typography>
                                        </Box>
                                        <Box
                                          display="flex"
                                          justifyContent="center"
                                        >
                                          <Slider
                                            aria-labelledby="price-margin"
                                            valueLabelDisplay="on"
                                            value={values.priceMargin}
                                            onChange={(event, newValue) =>
                                              form.mutators.setValue(
                                                "priceMargin",
                                                newValue
                                              )
                                            }
                                            min={-10}
                                            max={100}
                                          />
                                        </Box>
                                        <Box
                                          display="flex"
                                          justifyContent="center"
                                        >
                                          <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={submitMargin}
                                            disabled={false}
                                          >
                                            Mettre à jour
                                          </Button>
                                        </Box>
                                      </Box>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                      <Box mt={2} mb={2}>
                                        <Divider m={3} />
                                      </Box>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                      <Box
                                        display="flex"
                                        flexDirection="column"
                                      >
                                        <Box
                                          mt={1}
                                          mb={5}
                                          display="flex"
                                          justifyContent="center"
                                          flexDirection="column"
                                          alignItems="center"
                                        >
                                          <Typography variant="h6">
                                            Prix final
                                          </Typography>

                                          {project.totalArea && project.totalArea < 50 &&
                                            <Typography style={{marginTop: '1em', fontStyle: 'italic'}} variant="body1">
                                                Le prix au m² est augmenté de 20% pour les projets de moins de 50m²
                                            </Typography>
                                          }
                                        </Box>

                                        {isUpdatingProject && (
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                          >
                                            <CircularProgress size={40} />
                                          </Box>
                                        )}
                                        {!isUpdatingProject && (
                                          <>
                                            <Box display="flex" flexWrap="wrap">
                                              <Grid
                                                item
                                                xs={6}
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "flex-end",
                                                }}
                                              >
                                                <Typography
                                                  style={{
                                                    fontSize: "Roboto",
                                                    borderRadius: "4px",
                                                    textAlign: "right",
                                                    padding: "10px",
                                                    marginRight: "10px",
                                                    border: "#dc2c32 solid 1px",
                                                  }}
                                                  variant="h5"
                                                  color="primary"
                                                >
                                                  {formatterEuro.format(
                                                    quotation.totalPrice
                                                  )}
                                                </Typography>
                                              </Grid>
                                              <Grid item xs={6}>
                                                {!initialValues?.transportField
                                                  ?.totalPrice && (
                                                    <Box
                                                      display="flex"
                                                      alignItems="center"
                                                      justifyContent="center"
                                                      flexGrow={1}
                                                      marginTop="2rem"
                                                      marginBottom="2rem"
                                                    >
                                                      <CircularProgress
                                                        size={100}
                                                      />
                                                    </Box>
                                                  )}
                                                {initialValues?.transportField
                                                  ?.totalPrice && (
                                                    <Typography
                                                      style={{
                                                        marginLeft: "10px",
                                                        fontStyle: "italic",
                                                      }}
                                                    >
                                                      {formatterEuro.format(
                                                        (quotation.totalPrice -
                                                          initialValues
                                                            .transportField
                                                            .totalPrice) /
                                                        project.totalAreaFinal
                                                      )}{" "}
                                                      / m<sup>2</sup>
                                                      <br />
                                                      <small>
                                                        (Prix au m² hors
                                                        transport)
                                                      </small>
                                                    </Typography>
                                                  )}
                                              </Grid>
                                              <Grid item xs={12}>
                                                <Typography
                                                  style={{
                                                    marginRight: "10px",
                                                    fontStyle: "italic",
                                                    textAlign: "center",
                                                    marginTop: "20px",
                                                  }}
                                                >
                                                  {Math.round(
                                                    (project.priceMargin +
                                                      project.priceMarginTotal) *
                                                    100
                                                  ) / 100}
                                                  &nbsp;%
                                                  <br />
                                                  <small>Marge Totale</small>
                                                </Typography>
                                              </Grid>
                                            </Box>
                                          </>
                                        )}
                                      </Box>
                                    </Grid>

                                    <Box mt={2} mb={2}>
                                      <Divider m={3} />
                                    </Box>

                                    <Box textAlign="center" m={1}>
                                      <Typography variant="h6">
                                        Fichiers joints
                                      </Typography>
                                    </Box>

                                    <Grid
                                      className={"mb-4"}
                                      container
                                      spacing={2}
                                    >
                                      <UploadArea
                                        project={project}
                                        definitif={true}
                                      />
                                    </Grid>

                                    <Box
                                      md={12}
                                      mt={2}
                                      mb={2}
                                      display="flex"
                                      justifyContent="center"
                                    >
                                      <Box mr={1}>
                                        <Button
                                          variant="contained"
                                          disabled={
                                            loadingPdf ||
                                            !initialValues.transportField
                                          }
                                          color={"primary"}
                                          onClick={() => {
                                            setLoadingPdf(true);
                                            dispatch(
                                              projectsActions.getQuotation(
                                                projectId
                                              )
                                            ).then((data) => {
                                              setQuotationPdf({
                                                type: "QUOTATION_CODIFIED",
                                                file: window.URL.createObjectURL(
                                                  data.payload
                                                ),
                                                name: `${project.name}-${project.id}-devis-definitf-codifie.pdf`,
                                              });
                                              setOpen(true);
                                              setLoadingPdf(false);
                                            });
                                          }}
                                        >
                                          {!loadingPdf && (
                                            <>
                                              <GetApp /> Devis codifié
                                            </>
                                          )}
                                          {loadingPdf && (
                                            <CircularProgress size={20} />
                                          )}
                                        </Button>
                                      </Box>
                                      <Box ml={1}>
                                        <Button
                                          variant="contained"
                                          disabled={
                                            loadingPdfCustomer ||
                                            !initialValues.transportField
                                          }
                                          color={"primary"}
                                          onClick={() => {
                                            setLoadingPdfCustomer(true);
                                            dispatch(
                                              projectsActions.getCustomerQuotation(
                                                projectId
                                              )
                                            ).then((data) => {
                                              setQuotationPdf({
                                                type: "QUOTATION",
                                                file: window.URL.createObjectURL(
                                                  data.payload
                                                ),
                                                name: `${project.name}-${project.id}-devis-client-definitif.pdf`,
                                              });
                                              setOpen(true);
                                              setLoadingPdfCustomer(false);
                                            });
                                          }}
                                        >
                                          {!loadingPdfCustomer && (
                                            <>
                                              <GetApp /> Devis client
                                            </>
                                          )}
                                          {loadingPdfCustomer && (
                                            <CircularProgress size={20} />
                                          )}
                                        </Button>
                                      </Box>
                                    </Box>
                                  </div>
                                );
                              }}
                            />
                          )}
                        </MakeAsyncFunction>
                        <StepperButtons
                          submit={submit}
                          activeStep={activeStep}
                          projectId={projectId}
                          submitting={submitting}
                          last
                        />
                      </form>

                      {/* <Grid>
                        <Box md={12} display="flex" justifyContent="center">
                          <Button
                            variant="contained"
                            color={"primary"}
                            onClick={() => setShowConfirmRecalculateModal(true)}
                            disabled={generatingQuotation}
                          >
                            {!generatingQuotation && (
                              <>
                                <Sync /> Réinitialiser
                              </>
                            )}
                            {generatingQuotation && (
                              <CircularProgress size={20} />
                            )}
                          </Button>
                        </Box>
                      </Grid> */}
                      {/* <ValidationModal
                        open={showConfirmRecalculateModal}
                        onClose={() => setShowConfirmRecalculateModal(false)}
                        title={"Voulez-vous vraiment régénérer le devis ?"}
                        paragraph={
                          "Vous allez perdre vos modifications concernant la quantité des plaques. Les nouvelles quantités appliquées seront calculées automatiquement en fonction du projet."
                        }
                        loading={generatingQuotation}
                        onClick={regenerateQuotation}
                      /> */}
                      <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth={false}
                        maxWidth={"xl"}
                      >
                        <DialogContent>
                          {quotationPdf?.file && (
                            <>
                              <Document
                                file={quotationPdf?.file}
                                onLoadSuccess={onDocumentLoadSuccess}
                              >
                                {[...Array(pdfPagesNum)].map((x, i) => (
                                  <Fragment key={i}>
                                    <Page pageNumber={i + 1} />
                                    {i + 1 < pdfPagesNum && <hr />}
                                  </Fragment>
                                ))}
                              </Document>
                            </>
                          )}
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => {
                              setShowSendMailModal(true);
                              handleClose();
                            }}
                            color="primary"
                          >
                            Envoyer par mail
                          </Button>
                          <Button
                            onClick={() => {
                              let anchor = document.createElement("a");
                              anchor.download = quotationPdf?.name;
                              anchor.href = quotationPdf?.file;
                              anchor.dataset.downloadurl = [
                                "application/pdf",
                                anchor.download,
                                anchor.href,
                              ].join(":");
                              anchor.click();
                            }}
                            color="primary"
                          >
                            Télécharger
                          </Button>
                          <Button
                            onClick={handleClose}
                            color="primary"
                            autoFocus
                          >
                            Fermer
                          </Button>
                        </DialogActions>
                      </Dialog>

                      <SendMailModal
                        open={showSendMailModal}
                        defaultValue={getUser()?.decoded?.username}
                        type={quotationPdf?.type}
                        projectId={projectId}
                        onClose={() => setShowSendMailModal(false)}
                      />
                    </Container>
                  </div>
                );
              }}
            />
          )}
        </MakeAsyncFunction>
      )}
    </>
  );
}
