import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Chip,
  CircularProgress,
  Container,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Form } from "react-final-form";
import MakeAsyncFunction from "react-redux-promise-listener";
import { promiseListener } from "../../../../helpers/store";
import {
  EDIT_PROJECT_ERROR,
  EDIT_PROJECT_REQUEST,
  EDIT_PROJECT_RESPONSE,
  projectsActions,
} from "../../../../actions/projects.actions";
import { TextField } from "mui-rff";

import CreateIcon from "@material-ui/icons/Create";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { clientsActions } from "../../../../actions/clients.actions";
import { appActions } from "../../../../actions/app.actions";
import { Redirect, useParams } from "react-router-dom";
import { useStyles } from "../../../../helpers/project";
import StepperButtons from "../../../../components/projects/StepperButtons";
import { StepperHeader } from "../../../../components/projects/StepperHeader";
import { selectProject } from "../../../../helpers/schema";
import { history } from "../../../../helpers/history";
import ImgUploader from "../../../../components/imgUploader";

import { getProjectStep } from "../../../../helpers/project";

export default function Step2() {
  let { projectId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep] = useState(1);
  let submit;

  const isFetching = useSelector((state) => {
    return state.projects.isFetching;
  });

  const project = useSelector((state) => {
    return selectProject(state, projectId) || {};
  }, shallowEqual);

  useEffect(() => {
    dispatch(clientsActions.fetchClientsIfNeeded());
    dispatch(projectsActions.fetchProjectIfNeeded(projectId));
    dispatch(appActions.setNextPage("/projects/{currentProject}/step3"));
    dispatch(appActions.setCurrentProject(projectId));
  }, [dispatch, projectId, isFetching]);

  const finalClient =
    project?.client?.type === "FINAL" ? project?.client : project?.finalClient;

  if (!isFetching && project.name && getProjectStep(project) < 2) {
    return (
      <Redirect to={`/thermo/projects/${project.id}/step${getProjectStep(project)}`} />
    );
  }

  return (
    <>
      {isFetching && !project.name && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <CircularProgress size={100} />
        </Box>
      )}
      {!isFetching && !project.name && (
        <Alert severity="error">Projet non trouvé !</Alert>
      )}

      {project.name && (
        <MakeAsyncFunction
          listener={promiseListener}
          start={EDIT_PROJECT_REQUEST}
          resolve={EDIT_PROJECT_RESPONSE}
          reject={EDIT_PROJECT_ERROR}
        >
          {(onSubmit) => (
            <Form
              initialValues={{
                infos: project.infos,
              }}
              onSubmit={(form) => {
                dispatch(
                  appActions.setNextPage("/thermo/projects/{currentProject}/step3")
                );
                onSubmit(form);
              }}
              mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
              }) => {
                submit = handleSubmit;
                return (
                  <div className={classes.root}>
                    <StepperHeader
                      submit={submit}
                      activeStep={activeStep}
                      projectId={projectId}
                      submitting={submitting}
                      shouldLink={pristine}
                    />
                    <Container maxWidth="lg">
                      <Grid className={"mb-4"} item xs={12}>
                        <Box
                          mt={2}
                          mb={4}
                          display="flex"
                          justifyContent="center"
                        >
                          <Chip
                            label={project.name + " (#" + projectId + ")"}
                            color="primary"
                            size="large"
                            variant={"outlined"}
                          />
                        </Box>

                        <Table className={classes.table}>
                          <TableHead className={classes.tableHead}>
                            <TableRow>
                              <TableCell
                                className={classes.tableHeadLabel}
                                align="center"
                              >
                                Type
                              </TableCell>
                              <TableCell
                                className={classes.tableHeadLabel}
                                align="center"
                              >
                                Nom
                              </TableCell>
                              <TableCell
                                className={classes.tableHeadLabel}
                                align="center"
                              >
                                Email
                              </TableCell>
                              <TableCell
                                className={classes.tableHeadLabel}
                                align="center"
                              >
                                Adresse
                              </TableCell>
                              <TableCell
                                className={classes.tableHeadLabel}
                                align="center"
                              >
                                Actions
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow key={"client"} component="th" scope="row">
                              <TableCell align="center">Client</TableCell>
                              <TableCell align="center">
                                {project?.client?.name} {typeof project?.client?.firstname !== 'undefined' ? project?.client?.firstname : ''} (
                                {project?.client?.company})
                              </TableCell>
                              <TableCell align="center">
                                {project?.client?.email}
                              </TableCell>
                              <TableCell align="center">
                                {project?.client?.address}{" "}
                                {project?.client?.postCode}{" "}
                                {project?.client?.city}
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() =>
                                    history.push(`/thermo/projects/${projectId}/step1`)
                                  }
                                >
                                  <CreateIcon fontSize="small" />
                                </Button>
                              </TableCell>
                            </TableRow>
                            <TableRow
                              key={"finalClient"}
                              component="th"
                              scope="row"
                            >
                              <TableCell align="center">Client Final</TableCell>
                              <TableCell align="center">
                                {finalClient?.name} ({finalClient?.company})
                              </TableCell>
                              <TableCell align="center">
                                {finalClient?.email}
                              </TableCell>
                              <TableCell align="center">
                                {finalClient?.address} {finalClient?.postCode}{" "}
                                {finalClient?.city}
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() =>
                                    history.push(`/thermo/projects/${projectId}/step1`)
                                  }
                                >
                                  <CreateIcon fontSize="small" />
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>

                        <form className={classes.form} onSubmit={handleSubmit}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            flexGrow="1"
                            mt={4}
                            mb={4}
                          >
                            <Grid container spacing={3}>
                              <Grid item md={6} xs={12}>
                                <TextField
                                  className={classes.mb4}
                                  name="infos"
                                  id="filled-multiline-static"
                                  label="Informations complémentaires"
                                  helperText="Accès chantier, stockage sur place..."
                                  multiline
                                  rows={4}
                                  variant="outlined"
                                />
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                md={6}
                                className={classes.vCenter}
                              >
                                <div className={classes.relative}>
                                  <ImgUploader
                                    image={project.image?.contentUrl}
                                    beforeText="Ajouter une image"
                                    afterText="Modifier l'image"
                                    onResult={(result) =>
                                      form.mutators.setValue("image", result.id)
                                    }
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Box>

                          <StepperButtons
                            submit={submit}
                            activeStep={activeStep}
                            projectId={projectId}
                            submitting={submitting}
                            shouldLink={pristine}
                          />
                        </form>
                      </Grid>
                    </Container>
                  </div>
                );
              }}
            />
          )}
        </MakeAsyncFunction>
      )}
    </>
  );
}
