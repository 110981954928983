import {
  AppBar,
  Box,
  CardMedia,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  Radio as MuiRadio,
  RadioGroup,
  Tab,
  InputAdornment,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { Autocomplete, TextField } from "mui-rff";
import React, { useEffect } from "react";
import { Field, Form } from "react-final-form";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MakeAsyncFunction from "react-redux-promise-listener";
import { Redirect, useParams } from "react-router-dom";
import { accessoriesActions } from "../../../actions/accessories.actions";
import { appActions } from "../../../actions/app.actions";
import { insulationsActions } from "../../../actions/insulations.actions";
import { productsActions } from "../../../actions/product.actions";
import { productColorsActions } from "../../../actions/productColor.actions";
import {
  EDIT_PROJECT_ERROR,
  EDIT_PROJECT_REQUEST,
  EDIT_PROJECT_RESPONSE,
  projectsActions,
} from "../../../actions/projects.actions";
import StepperButtons from "../../../components/projects/StepperButtons";
import { StepperHeader } from "../../../components/projects/StepperHeader";
import { getProjectStep, useStyles } from "../../../helpers/project";
import {
  selectAccessories,
  selectInsulations,
  selectProducts,
  selectProductVariants,
} from "../../../helpers/schema";
import { promiseListener } from "../../../helpers/store";
import CreateIcon from "@material-ui/icons/Create";

const CustomTabList = withStyles({
  indicator: {
    backgroundColor: "#ffffff",
  },
  line: {},
})(TabList);

export default function Step3() {
  let { projectId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep] = React.useState(2);
  const [value, setValue] = React.useState("1");
  let submit;

  const isFetchingProject = useSelector((state) => {
    return state.projects.isFetching || state.accessories.isFetching;
  });

  const productColors = useSelector((state) => {
    return Object.values(state.productColors.all) || [];
  }, shallowEqual);

  const products = useSelector((state) => {
    return Object.values(selectProducts(state)) || [];
  }, shallowEqual);

  const productVariants = useSelector((state) => {
    return Object.values(selectProductVariants(state)) || [];
  }, shallowEqual);

  const insulations = useSelector((state) => {
    return Object.values(selectInsulations(state)) || [];
  }, shallowEqual);

  const accessories = useSelector((state) => {
    return Object.values(selectAccessories(state)) || [];
  }, shallowEqual);

  const project = useSelector((state) => {
    return state.projects.all[projectId] || {};
  }, shallowEqual);

  /* Recuperation des accessoires d'eclairements */
  const illuminations =
    accessories
      ?.filter(
        (accessory) =>
          accessory.reference === "NOILL" ||
          accessory.reference === "PLR" ||
          accessory.reference === "PC"
      )
      .sort((a, b) =>
        a.reference > b.reference ? 1 : b.reference > a.reference ? -1 : 0
      ) || [];

  useEffect(() => {
    dispatch(productsActions.fetchProductsIfNeeded());
    dispatch(insulationsActions.fetchInsulationsIfNeeded());
    dispatch(accessoriesActions.fetchAccessoriesIfNeeded());
    dispatch(productColorsActions.fetchProductColorsIfNeeded());
    dispatch(projectsActions.fetchProjectIfNeeded(projectId));
    dispatch(appActions.setNextPage("/renov/projects/{currentProject}/step4"));
    dispatch(appActions.setCurrentProject(projectId));
  }, [dispatch, projectId, isFetchingProject]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const pristineForm = (values) => {
    if (!values || !project) {
      return true;
    }
    if (
      !project?.productColor ||
      values?.productColor !== project?.productColor.toString()
    ) {
      return false;
    }

    if (
      !project?.productVariant ||
      values?.productVariant !== project?.productVariant.toString()
    ) {
      return false;
    }

    if (
      !project?.insulation ||
      values?.insulation !== project?.insulation.toString()
    ) {
      return false;
    }

    if (
      !project?.illumination ||
      values?.illumination !== project?.illumination.toString()
    ) {
      return false;
    }

    return true;
  };

  if (!isFetchingProject && project.name && getProjectStep(project) < 2) {
    return (
      <Redirect to={`/renov/projects/${project.id}/step${getProjectStep(project)}`} />
    );
  }

  const DEFAULT_COLORS = [5008, 8012, 7012, 7016];

  return (
    <>
      {isFetchingProject && !project.name && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <CircularProgress size={100} />
        </Box>
      )}
      {!isFetchingProject && !project.name && (
        <Alert severity="error">Projet non trouvé !</Alert>
      )}

      {project.name && (
        <MakeAsyncFunction
          listener={promiseListener}
          start={EDIT_PROJECT_REQUEST}
          resolve={EDIT_PROJECT_RESPONSE}
          reject={EDIT_PROJECT_ERROR}
        >
          {(onSubmit) => (
            <Form
              initialValues={{
                productColor: project.productColor?.toString(),
                productVariant: project.productVariant?.id.toString(),
                insulation: project.insulation?.toString(),
                illumination: project?.illumination?.id?.toString() || null,
                productMaxLength: project?.productMaxLength?.toString() || "8.25",
              }}
              onSubmit={(form) => {
                if (typeof form.productMaxLength === "string") {
                  form.productMaxLength = parseFloat(form.productMaxLength);
                }

                return onSubmit(form);
              }}
              mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }}
              render={({
                form,
                handleSubmit,
                submitting,
                pristine,
                values,
              }) => {
                submit = handleSubmit;
                return (
                  <div className={classes.root}>
                    <StepperHeader
                      submit={
                        !values.insulation
                          ? () => setValue("2")
                          : !values.illumination
                            ? () => setValue("3")
                            : submit
                      }
                      activeStep={activeStep}
                      projectId={projectId}
                      submitting={submitting}
                      shouldLink={pristineForm(values)}
                      disabled={!values.productColor || !values.productVariant}
                    />
                    <form onSubmit={submit}>
                      <Box mb={4}>
                        <TabContext value={value}>
                          <AppBar elevation={0} position="static">
                            <CustomTabList
                              indicatorColor="secondary"
                              centered
                              onChange={handleChange}
                              aria-label="simple tabs example"
                            >
                              <Tab label="Produits" value="1" />
                              <Tab label="Isolants" value="2" />
                              <Tab label="Eclairement" value="3" />
                            </CustomTabList>
                          </AppBar>

                          {/* **** PRODUITS TAB **** */}
                          <TabPanel value="1">
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Grid container justify="center">
                                  <Grid item xs={12} md={6}>
                                    <Autocomplete
                                      label="Sélectionner une couleur"
                                      name="productColor"
                                      options={Object.values(
                                        productColors.sort((productColor) =>
                                          DEFAULT_COLORS.includes(
                                            productColor.ral
                                          )
                                            ? -1
                                            : 1
                                        )
                                      )}
                                      renderOption={(option) => (
                                        <>
                                          {option?.name}
                                          {option.ral !== 0 && !DEFAULT_COLORS.includes(
                                            option.ral
                                          ) && (
                                              <small>&nbsp;(minimum 300m²)</small>
                                            )}
                                          {option.ral === 0 && (
                                                <small>&nbsp;(minimum 500m²)</small>
                                              )}
                                        </>
                                      )}
                                      autoHighlight
                                      getOptionValue={(option) =>
                                        option.id.toString()
                                      }
                                      getOptionLabel={(option) => option.name}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              {Object.values(productVariants).map(
                                (item, idx) => (
                                  item.product.enabled && <Grid item md={6} xs={12} key={idx}>
                                    <RadioGroup>
                                      <FormControlLabel
                                        name="productVariant"
                                        key={idx}
                                        label=""
                                        value={item.id.toString()}
                                        disabled={item.disabled}
                                        control={
                                          <Field
                                            name="productVariant"
                                            type="radio"
                                            render={({
                                              input: {
                                                name,
                                                value,
                                                onChange,
                                                checked,
                                                disabled,
                                                ...restInput
                                              },
                                            }) => (
                                              <Box flexGrow={1}>
                                                <Paper>
                                                  <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                  >
                                                    <Box
                                                      display="flex"
                                                      flexDirection="column"
                                                      m={2}
                                                      className={
                                                        classes.boxSize
                                                      }
                                                    >
                                                      <CardMedia
                                                        className={
                                                          item.image
                                                            ? classes.image
                                                            : classes.bgLightGrey
                                                        }
                                                        image={
                                                          process.env
                                                            .REACT_APP_API_URL +
                                                          item?.product?.image
                                                            ?.contentUrl
                                                        }
                                                        title={item.name}
                                                        alt={item.name}
                                                      />
                                                    </Box>
                                                    <Box
                                                      display="flex"
                                                      justifyContent="center"
                                                      flexDirection="column"
                                                      m={2}
                                                    >
                                                      <strong>
                                                        {item.name}
                                                      </strong>
                                                    </Box>
                                                    <Box
                                                      display="flex"
                                                      justifyContent="flex-end"
                                                      alignItems="center"
                                                      m={2}
                                                    >
                                                      <MuiRadio
                                                        name={name}
                                                        value={value}
                                                        onChange={(event) => {
                                                          form.mutators.setValue(
                                                            "insulation",
                                                            event.target
                                                              .value ===
                                                              project.productVariant?.id.toString()
                                                              ? project.insulation?.toString()
                                                              : null
                                                          );
                                                          onChange(event);
                                                        }}
                                                        checked={checked}
                                                        disabled={item.disabled}
                                                        required
                                                        inputProps={{
                                                          required: true,
                                                          ...restInput,
                                                        }}
                                                      />
                                                    </Box>
                                                  </Box>
                                                </Paper>
                                              </Box>
                                            )}
                                          />
                                        }
                                      />
                                    </RadioGroup>
                                  </Grid>
                                )
                              )}
                              <Grid item xs={12}>
                                <Grid container justify="center">
                                  <Grid item xs={12} md={6}>
                                    <Box display="flex" alignItems="center" gridGap=".5em">
                                      <CreateIcon fontSize="small" />
                                      <TextField
                                        size="small"
                                        label="Longueur maximale du produit"
                                        name="productMaxLength"
                                        defaultValue="8.25"
                                        variant="outlined"
                                      />
                                    </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </TabPanel>

                        {/* **** ISOLATION TAB **** */}
                        <TabPanel value="2">
                          <Grid container spacing={2}>
                            {insulations &&
                              insulations.length > 0 &&
                              insulations
                                .filter(
                                  (e) =>
                                    e?.wave ===
                                    productVariants.find(
                                      (e) =>
                                        e?.id?.toString() ===
                                        values?.productVariant?.toString()
                                    )?.wave
                                )
                                .map((item, idx) => (
                                  <Grid item md={6} xs={12} key={idx}>
                                    <RadioGroup>
                                      <FormControlLabel
                                        name="insulation"
                                        label=""
                                        value={item.id.toString()}
                                        disabled={item.disabled}
                                        control={
                                          <Field
                                            name="insulation"
                                            type="radio"
                                            render={({
                                              input: {
                                                name,
                                                value,
                                                onChange,
                                                checked,
                                                disabled,
                                                ...restInput
                                              },
                                            }) => (
                                              <Box flexGrow={1}>
                                                <Paper>
                                                  <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                  >
                                                    <Box
                                                      display="flex"
                                                      flexDirection="column"
                                                      m={2}
                                                      className={
                                                        classes.boxSize
                                                      }
                                                    >
                                                      <CardMedia
                                                        className={
                                                          item.image
                                                            ? classes.image
                                                            : classes.bgLightGrey
                                                        }
                                                        image={
                                                          process.env
                                                            .REACT_APP_API_URL +
                                                          item?.image
                                                            ?.contentUrl
                                                        }
                                                        title={item.name}
                                                        alt={item.name}
                                                      />
                                                    </Box>
                                                    <Box
                                                      display="flex"
                                                      justifyContent="center"
                                                      flexDirection="column"
                                                      m={2}
                                                    >
                                                      <strong>
                                                        {item.name}
                                                      </strong>
                                                    </Box>
                                                    <Box
                                                      display="flex"
                                                      justifyContent="flex-end"
                                                      alignItems="center"
                                                      m={2}
                                                    >
                                                      <MuiRadio
                                                        name={name}
                                                        value={value}
                                                        onChange={onChange}
                                                        checked={checked}
                                                        disabled={
                                                          item.disabled
                                                        }
                                                        required
                                                        inputProps={{
                                                          required: true,
                                                          ...restInput,
                                                        }}
                                                      />
                                                    </Box>
                                                  </Box>
                                                </Paper>
                                              </Box>
                                            )}
                                          />
                                        }
                                      />
                                    </RadioGroup>
                                  </Grid>
                                ))}
                          </Grid>
                        </TabPanel>

                        {/* **** ECLAIREMENT TAB **** */}
                        <TabPanel value="3">
                          <Grid container justify="center" spacing={2}>
                            <Grid item md={6} xs={12}>
                              {illuminations &&
                                illuminations.map((item, idx) => (
                                  <RadioGroup key={idx}>
                                    <FormControlLabel
                                      name="illumination"
                                      label=""
                                      value={item?.id?.toString()}
                                      disabled={item.disabled}
                                      control={
                                        <Field
                                          name="illumination"
                                          type="radio"
                                          render={({
                                            input: {
                                              name,
                                              value,
                                              onChange,
                                              checked,
                                              disabled,
                                              ...restInput
                                            },
                                          }) => (
                                            <Box flexGrow={1} m={2}>
                                              <Paper>
                                                <Box
                                                  display="flex"
                                                  justifyContent="space-between"
                                                >
                                                  <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    flexDirection="column"
                                                    m={2}
                                                  >
                                                    {item.name}
                                                  </Box>
                                                  <Box
                                                    display="flex"
                                                    justifyContent="flex-end"
                                                    alignItems="center"
                                                    m={2}
                                                  >
                                                    <MuiRadio
                                                      name={name}
                                                      value={value}
                                                      onChange={onChange}
                                                      checked={checked}
                                                      disabled={item.disabled}
                                                      required
                                                      inputProps={{
                                                        required: true,
                                                        ...restInput,
                                                      }}
                                                    />
                                                  </Box>
                                                </Box>
                                              </Paper>
                                            </Box>
                                          )}
                                        />
                                      }
                                    />
                                  </RadioGroup>
                                ))}
                            </Grid>
                          </Grid>
                        </TabPanel>
                      </TabContext>
                    </Box>
                    <Container maxWidth="lg">
                      <StepperButtons
                        submit={
                          !values.insulation
                            ? () => setValue("2")
                            : !values.illumination
                              ? () => setValue("3")
                              : submit
                        }
                        activeStep={activeStep}
                        projectId={parseInt(projectId, 10)}
                        submitting={submitting}
                        shouldLink={pristineForm(values)}
                        disabled={
                          !values.productColor || !values.productVariant
                        }
                      />
                    </Container>
                  </form>
                  </div>
      );
              }}
            />
          )}
    </MakeAsyncFunction >
      )
}
    </>
  );
}
